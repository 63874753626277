.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-background {
  background: #fff;
  height: calc(100% - 40px);
  margin-top: 40px;
  padding: 24px 0;
}
.layout-sidebar {
  background: #fff;

}
.layout-message-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  height: 100%
}
.message-input {
  display: flex;
  flex-wrap: wrap;
}
.message-input > div {
  margin-bottom: 0;
}
.message {
  margin-bottom: 10px;
  max-width: 30%;
}
.message .message-text {
  background: #f6f6f6;
  display: table;
  padding: 10px;
  border-radius: 8px 8px 8px 0;
  max-width: calc(100% - 50px);
  margin-left: 8px;
}

.message.ownMessage {
  margin-left: auto;
}
.message.ownMessage .message-text {
  background: #1890ff;
  color: #fff;
  border-radius: 8px 8px 0 8px;
  margin-left: 0;
  margin-right: 8px
}
.messages {
  overflow-y: scroll;
  padding-right: 15px;
}
.message-wrap {
  display: flex;
  align-items: flex-end;
}
.message.ownMessage .ant-avatar {
  order: 1;
}

.message.ownMessage .message-wrap {
  justify-content: flex-end;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 10px;
}
.message-date {
  font-size: 12px;
  color: #929292;
  margin-top: 9px;
}

.message.ownMessage .message-date {
  text-align: right;
}
.message-input .ant-form-item-has-success {
  width: 100%;
}
.message-input .ant-upload.ant-upload-select-picture-card {
  width: auto;
  height: auto;
  margin: 0;
  border: none;
}
.message-input .ant-form-item-has-success .ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border: 1px dashed #d9d9d9;
}
.message-image {
  border-radius: 8px;
}